import React from "react"

import SEO from "../components/seo"
import AndroidUpdateEffectClone from "../components/AndroidUpdateEffectClone/AndroidUpdateEffectClone"

const IndexPage = () => <>
  <SEO title="ioxua" />
  <AndroidUpdateEffectClone/>
</>

export default IndexPage
